import React, {useState} from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import OtpInput from "react-otp-input";
import ImageGallery from 'react-image-gallery';

import { DigitInputs, Digit } from 'digitinputs-react'

const IndexPage = ({ data }) => {
  const [flipped, setFlipped] = useState(false);
  const [unlocked, setUnlocked] = useState(false);

  const images = data.allFile.edges.map(({ node }) => node.childImageSharp.fluid).map(fluidImage => {return {
    original: fluidImage.src,
    srcSet: fluidImage.srcSet
  }});

  const handleDigitsChange = (value) => {
    if (value.asString === '091894') {
      setUnlocked(true);
    }
  }

  return <Layout>
    <div className="flip-card" style={{marginTop: '5rem'}}>
      <div className={"flip-card-inner " + (flipped ? "is-flipped" : "")}>
        <div className="flip-card-front">
          <section id="main">
            <Header/>
            <Footer flip={() => setFlipped(true)}/>
          </section>
        </div>
        <div className="flip-card-back" style={{padding: "1.5rem"}}>
          {
            unlocked ?
                <React.Fragment>
                  <h2> eric would like to invite you to a weekend getaway! </h2>
                  <h3> Portland, ME </h3>
                  <h3> 12/3/21 - 12/5/21 </h3>

                  <div style={{marginTop: '2rem'}}>
                    <ImageGallery items={images}
                                  showNav={false}
                                  showThumbnails={false}
                                  showFullscreenButton={false}
                                  showPlayButton={false}
                                  showIndex={true}
                                  autoPlay={true}
                    />
                  </div>
                </React.Fragment>
                : <React.Fragment>
                <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center'}}>
                  <form id={"passcode"}>
                    <DigitInputs onDigitsChange={handleDigitsChange}>
                      <Digit/>
                      <Digit/>
                      <Digit/>
                      <Digit/>
                      <Digit/>
                      <Digit/>
                    </DigitInputs>
                  </form>
                </div>
              </React.Fragment>

          }
        </div>
      </div>
    </div>
  </Layout>;
};

export const pageQuery = graphql`
  query ImagesForGallery {
    allFile {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 500
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
            fluid {
              srcSet
              src
            }
          }
        }
      }
    }
  }
`

export default IndexPage;
