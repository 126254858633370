import React from 'react';
import config from '../../config';

export default function Footer(props) {
  console.log(props);
  return (
    <footer>
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { icon, name, url } = social;
          return (
            <li key={url}>
                    <a href={url}>
                      <div className={`icon ${icon}`}>
                        <span className="label">{name}</span>
                      </div>
                    </a>
            </li>
          );
        })}
        <li onClick={() => props.flip()} key='gift'>
            <div className={`icon fa-gift`}>
                <span className="label">Gift</span>
            </div>
        </li>
      </ul>
    </footer>
  );
}
